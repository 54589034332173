import React, {useCallback, useEffect, useState} from 'react'
import {useDispatch, useSelector} from 'react-redux';
import history from '../../history';
import {createEntity, updateEntity, getEntity, reset} from '../../reducers/eventReducer';

import {Controller, useFieldArray, useForm, useWatch} from "react-hook-form";
import slugify from "slugify";

//HAD TO USE AS DAYJS HAS BUG FOR TIMEZONES
import moment from 'moment-timezone';

import formConfig from '../../config/formConfig';

import {
    CButton,
    CButtonGroup,
    CFormGroup,
    CFormText,
    CInputFile,
    CLabel,
    CNav,
    CNavItem,
    CNavLink,
    CTabContent,
    CTabPane,
    CTabs
} from "@coreui/react";

import { Controlled as CodeMirror } from 'react-codemirror2'

import TextEditor from "../../modules/TextEditor";
import Dropzone from "react-dropzone";
import {contextAwareUrl, formatDateTimeForInput} from "../../helpers";
import EventUpdateFormSteamSettings from "./EventUpdateFormStreamSettings";
import EventUpdateFormSetup from "./EventUpdateFormSetup";
import EventUpdateFormMediaAssets from "./EventUpdateFormMediaAssets";
import EventUpdateFormMeta from "./EventUpdateFormMeta";
import EventUpdateFormInformation from "./EventUpdateFormInformation";
import {Card, CardBody} from "reactstrap";
import EventUpdateFormAdvanced from "./EventUpdateFormAdvanced";

moment.tz.setDefault("Europe/London");

const EventUpdateForm = ({id}) => {

    const dispatch = useDispatch();

    const currentEnvironment = useSelector(state => state.context.currentEnvironment);
    const event = useSelector(state => state.event.entity);
    const loading = useSelector(state => state.event.loading);
    const updating = useSelector(state => state.event.updating);
    const updateSuccess = useSelector(state => state.event.updateSuccess);
    const errorMessage = useSelector(state => state.event.errorMessage);

    const [isNew] = useState(!id);



    const [content, setContent] = React.useState('test');




    // initial load
    useEffect(() => {
        if (isNew) {
            dispatch(reset());
        } else {
            dispatch(getEntity(id));
        }
    }, []);

    // // if event changes reload form
    useEffect(() => {
        form.reset({
            ...event,
            start: (formatDateTimeForInput(event.start))
        });
    }, [event]);

    // if successful save redirect back to events
    useEffect(() => {
        if (updateSuccess) {
            if (isNew) {
                history.push(contextAwareUrl('/events'));
            } else {
                history.push(contextAwareUrl('/events/' + id));
            }
        }
    }, [updateSuccess]);

    const form = useForm({
        defaultValues: isNew ? {} : event
    });

    const custom = useWatch({
        control: form.control,
        name: 'custom', // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    });

    useEffect(() => {
        console.log('custom --- : ', custom);
        setContent(JSON.stringify(custom, null, 2));
    }, [custom]);

    const defaultPerson = {
        ...(formConfig.config.fields?.peopleName?.show && {"name": ""}),
        ...(formConfig.config.fields?.peopleTitle?.show && {"title": ""}),
        ...(formConfig.config.fields?.peoplePhoto?.show && {"photo": ""}),
    };

    const defaultProgramme = {
        ...(formConfig.config.fields?.programmeDate?.show && {"date": ""}),
        ...(formConfig.config.fields?.programmeOpus?.show && {"opus": ""}),
        ...(formConfig.config.fields?.programmeComposer?.show && {"composer": ""}),
        ...(formConfig.config.fields?.programmeTitle?.show && {"title": ""}),
    };

    const people = useFieldArray({
        control: form.control,
        name: "custom.people"
    });

    const programme = useFieldArray({
        control: form.control,
        name: "custom.programme"
    });

    const handleTitleChange = e => {
        e.persist();
        form.setValue('slug', slugify(e.target.value, { lower: true, strict: true }));
    };

    // #38 added settings for videoAspect - these are enums in the API but hardcoded here
    // todo need some FE tweak on this to set this properly
    const [videoAspect, setVideoAspect] = useState(event.videoAspect || '16:9');
    const handleVideoAspectChange = e => {
        e.persist();
        setVideoAspect(e.target.value);
    }

    const showFiles = (acceptedFiles) => {
        const results = document.querySelector('.upload-results');
        results.innerHTML = "";
        acceptedFiles.map(file => (
            results.insertAdjacentHTML('beforeend', `<li>${file.name}, ${file.type}</li>`)
        ));
    };

    const saveEntity = (formData) => {

        const dateTime = moment.tz(`${formData.start}`, "Europe/London").utc();

        const eventData = {
            "environmentId": currentEnvironment.id,
            "title": formData.title,
            "start": dateTime,
            "introduction": formData.introduction,
            "description": formData.description,
            "metaDescription": formData.metaDescription,
            "slug": formData.slug,
            "bannerImage": formData.bannerImage,
            "thumbnailImage": formData.thumbnailImage,
            "programmeFile": formData.programmeFile,
            "streamFileUrl": formData.streamFileUrl,
            "streamAutoStart": formData.streamAutoStart,
            "streamEnableVod": formData.streamEnableVod,
            "streamVodDuration": formData.streamVodDuration,
            "videoAspect": videoAspect,
            "custom": {
                ...event.custom,
                ...formData.custom,
                people: formData.custom.people,
                programme: formData.custom.programme,
                trailerUrl: formData.custom.trailerUrl
            }
        };

        console.log('eventData: ', eventData);

        console.log('formData.custom: ', formData.custom);

        if (isNew) {
            dispatch(createEntity(eventData));
        } else {
            eventData.id = id;
            dispatch(updateEntity(eventData));
        }
    };

    return (
        <>
            {loading ? (
                <div>Loading...</div>
            ) : (
                <form onSubmit={form.handleSubmit(saveEntity)}>

                    <CTabs activeTab="setup">
                        <CNav variant="tabs">
                            <CNavItem>
                                <CNavLink data-tab="setup">
                                    Setup
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="media-assets">
                                    Media Assets
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="meta">
                                    Metadata
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="information">
                                    Information
                                </CNavLink>
                            </CNavItem>
                            <CNavItem>
                                <CNavLink data-tab="streaming">
                                    Stream Settings
                                </CNavLink>
                            </CNavItem>
                            {/*<CNavItem>
                                <CNavLink data-tab="advanced">
                                    Advanced
                                </CNavLink>
                            </CNavItem>*/}
                        </CNav>

                        <CTabContent fade={false}>
                            <CTabPane data-tab="setup">
                                <Card className="shadow-none">
                                    <CardBody>
                                        <EventUpdateFormSetup
                                            form={form}
                                            handleTitleChange={handleTitleChange}
                                        />
                                    </CardBody>
                                </Card>
                            </CTabPane>
                            <CTabPane data-tab="media-assets">
                                <Card className="shadow-none">
                                    <CardBody>
                                        <EventUpdateFormMediaAssets form={form} />
                                    </CardBody>
                                </Card>
                            </CTabPane>
                            <CTabPane data-tab="meta">
                                <Card className="shadow-none">
                                    <CardBody>
                                        <EventUpdateFormMeta form={form} />
                                    </CardBody>
                                </Card>
                            </CTabPane>
                            <CTabPane data-tab="information">
                                <Card className="shadow-none">
                                    <CardBody>
                                        <EventUpdateFormInformation
                                            defaultPerson={defaultPerson}
                                            defaultProgramme={defaultProgramme}
                                            form={form}
                                            people={people}
                                            programme={programme}
                                            />
                                    </CardBody>
                                </Card>
                            </CTabPane>
                            <CTabPane data-tab="streaming">
                                <Card className="shadow-none">
                                    <CardBody>
                                        <EventUpdateFormSteamSettings
                                            showFiles={showFiles}
                                            handleVideoAspectChange={handleVideoAspectChange}
                                            videoAspect={videoAspect}
                                            form={form}
                                        />
                                    </CardBody>
                                </Card>
                            </CTabPane>
                            {/*<CTabPane data-tab="advanced">
                                <Card className="shadow-none">
                                    <CardBody>
                                        <EventUpdateFormAdvanced
                                            form={form}
                                            cmOptions={cmOptions}
                                            handleCustomChange={()=>{}}
                                            setContent={setContent}
                                            content={content}
                                        />
                                    </CardBody>
                                </Card>
                            </CTabPane>*/}
                        </CTabContent>
                    </CTabs>

                    <div className="mb-4">

                            <button type="submit" className="btn btn-primary d-flex align-items-center"
                                    disabled={updating}>
                                <span className="cil-check-alt btn-icon mr-2"/>
                                Save event
                            </button>

                    </div>

                    {errorMessage && (
                        <div className="alert alert-danger">{errorMessage.message}</div>
                    )}
                </form>
            )}
        </>
    )
};

export default EventUpdateForm;
